.forget-link {
    text-decoration: none;
    display: block;
    margin-top: 10px;
}

.line {
    width: 137px;
}

.line-text {
    margin: 0px 17px;
    padding-top: 6px;
    font-size: 13px;
}