a.menu-items.active {
    background: #6CF102;
    color: #fff;
  }

  img.dashboard-logo {
    height: 68px;
    width: auto;
}

.flex-col::-webkit-scrollbar {
  width: 5px;
}

.flex-col::-webkit-scrollbar-thumb {
  background-color: #000;
}

.notification-box img {
  height: 26px;
  width: 26px;
}
.notification-box ul.dropdown-menu {
  width: 100%;
  min-width: 400px;
  left: 0 !IMPORTANT;
  right: 0 !important;
  margin: 0 auto !important;
  box-shadow: 0 4px 15px #ddd;
  border: none;
  padding: 0 0 20px 0 !important;
}
.f-13
{
  font-size: 13px;
}
.f-12
{
  font-size: 12px;
}
.notification-box ul.dropdown-menu li {
  border-bottom: 1px solid #eee;
  padding: 12px 0;
}