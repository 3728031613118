.ql-container {
  min-height: 200px !important;
}

.buttons2 {
  float: left;
}

.addFaqs {
  position: absolute;
  top: 50px;
  right: 10%;
}