.table-head {
    background-color: #2b2b2b;
}

.common-table-body {
    background-color: #FFFFFF;
}

.table-view {
    width: 100% !important;
    overflow-x: auto;
}

.table-head th {
    color: #fddc5c;
    font-weight: 600;
}