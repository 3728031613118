@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Libre+Baskerville:wght@400;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Open+Sans:wght@300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Rubik:wght@300;400;500;600;700;800;900&family=Rye&family=ZCOOL+QingKe+HuangYou&display=swap');

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
strong {
  font-family: 'Montserrat';
}

:root {
  --dark: #2B2B2B;
  --light: #757575;
  --darkShade: #fddc5c;
}
.sidebare .sider .rs-sidenav-item-active img {
  filter: brightness(0.1);
}
.origin-top-right a:hover {
  background: #2b2b2b;
  color: #fff;
}
.origin-top-right a {
  text-decoration: none !important;
  background: var(--darkShade);
  border-bottom: 1px solid #fff;
}
.user-appoint-r button.MuiToggleButton-root.Mui-selected {
  background: var(--darkShade);
}
button.btn.btn-primary:hover {
  background-color: #fcc200 !important;
}
button.btn.btn-primary {
  background: var(--darkShade) !important;
  border-color: var(--darkShade) !important;
  color: #000 !important;
}
.dark {
  color: var(--darkShade) !important;
}
.dark-bg {
  background-color: var(--darkShade) !important;
}
.grey-dark {
  background-color: var(--dark) !important;
}

.light-menu {
  color: #757575;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.f-22 {
  font-size: 22px !important;
}

.f-14 {
  font-size: 14px;
}

.f-28 {
  font-size: 28px;
}

.bold {
  font-weight: 600;
}

main {
  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dashboard-card {
  cursor: pointer;
}

.hireBut {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
}

.hireBut button {
  margin-left: 10px;
}

.setImage {
  width: 150px !important;
  height: 150px !important;
  border: none !important;
}

.setplusImage {
  width: 50px !important;
  height: 50px !important;
  border: 100px !important;
}

img.previewImage.setImage {
  border: none !important;
}

.setImage {
  object-fit: contain;
  padding: 2px;
}

.sider a.light-menu.active,
.sidebare .sider .rs-sidenav-item-active {
  background: #f5f5f5 !important;
  border-radius: 16px 0 0 16px !important;
  color: #000 !important;
}

img.menu-icon {
  height: 22px;
  width: 22px;
}

.sider a.light-menu {
  padding: 12px 0;
}

.header-top h5 {
  padding-left: 22px;
  text-transform: uppercase;
  font-weight: 500;
}

li.notification-item {
  border-bottom: 1px dashed #ebe9e9 !important;
  padding: 5px;
}

.right-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification-buttons button {
  padding: 2px 20px;
  font-size: 15px;
}

img.notify-icn {
  height: 44px;
}

.main-table table td button.btn.icon {
  border: none !important;
  padding: 0 !important;
}

.main-table table td button.btn.icon span.mx-2 {
  margin: 0 4px !important;
}

.top-search input {
  padding: 12.5px;
}

.sider a.light-menu:hover {
  color: var(--light) !important;
}

.page.number,
.page {
  border: 1px solid var(--light);
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page.number.active {
  background: var(--dark);
  color: #fff;
}

.main-table table tbody tr {
  border-bottom: 1px solid #D8D8D8;
}

.main-table table td button.btn.icon:hover {
  color: #000 !important;
  background: transparent !important;
}

button.btn.expanded-btn {
  padding: 12px 30px;
  font-size: 15px;
  line-height: normal;
}
.rdw-editor-main {
  padding: 10px 15px !important;
}
.transaction-tbl table td span {
  white-space: normal;
}
.rdw-editor-toolbar {
  background: #e5e7eb !important;
}
img.user-images {
  height: 110px;
  width: 110px;
  border-radius: 100%;
  object-fit: cover;
}

.space-card {
  padding: 30px;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none !important;
}

.foto {
  border: 1px dashed #ddd;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.driver-rghtt {
  font-size: 14px;
}

.driver-left {
  font-size: 15px;
}

.users-icon.blue-bg {
  background: #E9F9F9;
}

.detail-col .driver-flex {
  justify-content: space-between;
}

.dashboard-card {
  padding: 30px 20px;
}

.users-icon.pink-bg {
  background: #FFF1EF;
}

.users-icon.yellow-bg {
  background: #FFF3E0;
}

.users-icon {
  background: #DCDCDC;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
}

img.dashboard-img {
  height: 32px;
}

.chart-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-flex select.form-select {
  border-radius: 40px;
  font-size: 14px;
}

.chart-flex span.line {
  display: none;
}

.text-right {
  text-align: right;
}
nav.sider.tras a img {
  height: 22px;
  width: 22px;
  filter: brightness(0.5);
}
.dash-img {
  display: flex;
  align-items: center;
  gap: 10px;
}

nav.sider.tras a {
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 15px;
}

nav.sider.tras,
nav.sider.tras a {
  background-color: transparent !important;
  text-decoration: none;
  color: #757575 !important;
  font-weight: 500;
  position: relative;
}

.sidebare .user-icon a::before {
  content: "";
  background: url(@/assets/user.png);
  position: absolute;
  left: 14px;
  top: 16px;
  height: 22px;
  width: 22px;
  background-size: 22px;
}

.sidebare .setting-icon a::before {
  content: "";
  background: url(@/assets/settings.png);
  position: absolute;
  left: 14px;
  top: 12px;
  height: 22px;
  width: 22px;
  background-size: 22px;
}

.detail-col .driver-flex b {
  font-weight: 500 !important;
}

.sidebare .user-icon ul li a::before {
  display: none !important;
}

.sidebare .user-icon ul li {
  list-style: disc !important;
}

.sidebare .user-icon ul {
  padding-left: 40px !important;
}

.sidebare .setting-icon ul li a::before {
  display: none !important;
}

.sidebare .setting-icon ul li {
  list-style: disc !important;
}

.sidebare .setting-icon ul {
  padding-left: 40px !important;
}

.sider a.light-menu.active,
.sidebare .sider .rs-sidenav-item-active {
  background: var(--darkShade) !important;
  border-radius: 12px 12px !important;
}

.sider ul ul a.rs-dropdown-item-active {
  color: #f5f5f5 !important;
  font-weight: 500;
}


img.chaperone-icon {
  height: 180px;
  width: auto;
  margin-top: 6px;
}

label span {
  font-weight: 700;
}

.popupRes p span {
  font-weight: 600;
  margin-right: 5px;
}

.popupRes p {
  margin: 0;
  padding: 6px 0;
}

.quryCont {
  margin: 0;
  padding: 0;
}

.quryCont div {
  margin: 0;
  padding: 0;
}

.quryCont .col-md-2,
.col-md-3 {
  font-size: 15px;
  font-weight: 600;
}

#modal-modal-title {
  font-weight: 600;
}

.notification-box .dropdown-toggle::after {
  display: none;
}

.col-md-3.no-space {
  padding: 0;
}

.transaction-tbl table td span {
  white-space: normal;
}